.form {
  display: inline-block;
  margin-bottom: 1.625rem;
  margin-right: 1rem;
}

.header {
  height: 2.2rem;
  display: flex;
  align-items: flex-end;
  margin-bottom: -0.2rem;
  font-size: 1rem;
}

.header:first-child {
  margin-top: -1rem;
}
