.container {
  height: 2rem;
  display: flex;
  position: relative;
  align-items: flex-end;
}

.side {
  font-size: 0.75rem;
  width: 2rem;
  text-decoration: overline;
  text-align: center;
  position: relative;
  top: 0.125rem;
}
