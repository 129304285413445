.container {
  margin-bottom: -0.25rem;
}

.header {
  height: 2.25rem;
  margin-bottom: -0.25rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.trianglePath {
  fill: var(--shadow);
  stroke: var(--text);
  stroke-width: 0.5%;
}

.label {
  font-family: serif;
}

.side {
  text-decoration: overline;
}

.arc {
  fill: transparent;
  stroke: rgba(0, 0, 255, 0.87);
  stroke-width: 0.5%;
}

.circumcircle,
.incircle,
.euler {
  stroke-width: 0.5%;
  fill: transparent;
  stroke: rgba(200, 0, 0, 0.87);
}

.circumcenter,
.incenter,
.orthocenter,
.centroid {
  fill: rgba(128, 0, 0, 0.87);
}

.noEuler {
  fill: rgba(128, 0, 0, 0.87);
}
