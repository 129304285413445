.container {
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: flex-end;
  margin-right: 1rem;
}

.label {
  font-size: 50%;
  position: absolute;
  top: 100%;
}

.input {
  width: 100%;
  text-align: center;
  font-size: 1rem;
}

.cursor {
  background-color: rgba(0, 0, 0, 0.12);
}
