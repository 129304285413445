:root {
  --divider: #4caf50;
  --gridline: #4caf5020;
}

.graph-paper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 36rem;
  margin: 0 auto;
  background: linear-gradient(var(--divider), var(--divider)) 0 2rem / 100% 2px
    no-repeat;
  background-color: var(--fadedYellow);
}

/* Left rule appears */
@media screen and (min-width: 600px) {
  .graph-paper {
    background: /* top rule */ linear-gradient(var(--divider), var(--divider)) 0
        2rem / 100% 2px no-repeat,
      /* left rule */ linear-gradient(var(--divider), var(--divider)) 2rem 0 /
        2px 100% no-repeat,
      /* 3-hole punch on the side */
        radial-gradient(
          circle,
          #eee 0,
          #ddd 0.5rem,
          transparent 0.5rem,
          transparent
        )
        0.5rem -4rem / 1rem 16rem repeat-y;
    background-color: var(--fadedYellow);
    padding-left: 2rem;
  }
}

/* Right rule appears */
@media screen and (min-width: 756px) {
  .graph-paper {
    background: /* top rule */ linear-gradient(var(--divider), var(--divider)) 0
        2rem / 100% 2px no-repeat,
      /* left rule */ linear-gradient(var(--divider), var(--divider)) 4rem 0 /
        2px 100% no-repeat,
      /* right rule */ linear-gradient(var(--divider), var(--divider)) top right
        2rem / 2px 100% no-repeat,
      /* 3-hole punch on the side */
        radial-gradient(
          circle,
          #eee 0,
          #ddd 0.5rem,
          transparent 0.5rem,
          transparent
        )
        1rem -4rem / 1rem 16rem repeat-y;
    border-radius: 2px;
    box-shadow: 0 4px 4px var(--shadow);
    padding-right: 2rem;
    padding-left: 4rem;
    background-color: var(--fadedYellow);
  }
}

/* Grey backdrop appears */
@media screen and (min-width: 816px) {
  .graph-paper {
    margin: 1rem;
  }
}

.graph-paper header {
  height: 2.2rem;
  padding: 0 0.5rem;
  margin-bottom: -0.2rem;
  display: flex;
  align-items: flex-end;
}

/* Top trisector lines appear */
@media screen and (min-width: 780px) {
  .graph-paper header {
    background: linear-gradient(
        90deg,
        var(--divider) 0,
        var(--divider) 2px,
        transparent 2px,
        transparent 100%,
        var(--divider) 100%
      )
      0 0 / 33.4% 2rem repeat-x;
  }
}

/* vertical and horizontal gridlines */
.graph-paper main {
  flex-grow: 1;
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  border-bottom: 2px solid var(--gridline);
  padding: 1rem;
  background: linear-gradient(
        0deg,
        var(--gridline) 0,
        var(--gridline) 2px,
        transparent 2px,
        transparent 20%,
        var(--gridline) 20%,
        var(--gridline) calc(20% + 1px),
        transparent calc(20% + 1px),
        transparent 40%,
        var(--gridline) 40%,
        var(--gridline) calc(40% + 1px),
        transparent calc(40% + 1px),
        transparent 60%,
        var(--gridline) 60%,
        var(--gridline) calc(60% + 1px),
        transparent calc(60% + 1px),
        transparent 80%,
        var(--gridline) 80%,
        var(--gridline) calc(80% + 1px),
        transparent calc(80% + 1px),
        transparent
      )
      0 0 / 100% 5rem repeat-y,
    linear-gradient(
        90deg,
        var(--gridline) 0,
        var(--gridline) 2px,
        transparent 2px,
        transparent 20%,
        var(--gridline) 20%,
        var(--gridline) calc(20% + 1px),
        transparent calc(20% + 1px),
        transparent 40%,
        var(--gridline) 40%,
        var(--gridline) calc(40% + 1px),
        transparent calc(40% + 1px),
        transparent 60%,
        var(--gridline) 60%,
        var(--gridline) calc(60% + 1px),
        transparent calc(60% + 1px),
        transparent 80%,
        var(--gridline) 80%,
        var(--gridline) calc(80% + 1px),
        transparent calc(80% + 1px),
        transparent
      ) -0px 0 / 5rem 100% repeat-x;
}

.graph-paper footer {
  height: 2rem;
  padding: 0.5rem;
  font-size: 0.5rem;
}
