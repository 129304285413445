.container {
  margin-top: 0.125rem;
  margin-bottom: -0.125rem;
  height: 1rem;
  display: flex;
  align-items: flex-end;
}

.input {
  position: relative;
  height: 0.75rem;
  width: 0.75rem;
  bottom: 0.125rem;
  margin-right: 0.25rem;
}

.label {
  font-size: 0.75rem;
}
