@import-normalize;

:root {
  --text: rgba(0, 0, 0, 0.87);
  --text2: rgba(0, 0, 0, 0.54);
  --shadow: rgba(0, 0, 0, 0.2);
  --fadedYellow: #fffde7;
}

html {
  background-color: #eee;
  box-sizing: border-box;
  color: var(--text);
  font-family: sans-serif;
  font-size: 24px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 100vh;
}

input {
  border: 0;
  background-color: transparent;
  border-bottom: 2px solid var(--text2);
}

h1,
h2,
h3 {
  margin: 0;
  font-weight: 400;
}

h1 {
  font-size: 1.125rem;
}

p {
  line-height: 1rem;
  font-size: 75%;
  margin: 1rem 0;
}

button {
  border: 0;
  padding: 0;
}

.hidden {
  visibility: hidden;
}
