.container {
  height: 1.125rem;
  display: flex;
  align-items: flex-end;
  margin-top: 0;
  margin-bottom: -0.125rem;
}

.input,
.label {
  font-size: 0.625rem;
  margin-right: 0.5rem;
}

.input {
  margin-bottom: 0.25rem;
}
